<template>
   <div>
      <div class="header-page-mae-elenials mx-4">
         <div class="text-center mt-4 pt-6 mb-4 header-title-color-mae-elenials">
            <div class="d-flex justify-space-between">
               <v-btn icon @click="voltar">
                  <v-icon>bi-arrow-left</v-icon>
               </v-btn>
               <div class="mini-box-header d-flex justify-center mr-1">
                  <img
                     alt="Logo PV"
                     src="../../assets/MaeElenials/header-mae-elenials.svg"
                     height="22"
                     class="align-self-center"
                  />
               </div>
               <div class="text-h6 font-weight-bold mb-2 mr-2">Rede de apoio Materno</div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'Header',
   methods: {
      voltar() {
         this.$router.go(-1);
      }
   }
};
</script>
