<template>
   <div class="text-center mb-4 mx-6 mt-2">
      <CardPapoRetoMaternidade />
   </div>
</template>

<script>
import CardPapoRetoMaternidade from '@/components/MaeElenials/CardPapoRetoMaternidade';

export default {
   name: 'InicioJornada',
   components: { CardPapoRetoMaternidade },
   data: () => ({
      loading: false
   })
};
</script>

<style></style>
