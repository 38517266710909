<template>
   <v-dialog
      v-model="dialog"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      fullscreen
      @keydown.esc="fechar"
   >
      <v-card v-if="dialog">
         <v-card-title class="dialog-plano-desenvolvimento-individual-header">
            <v-spacer />

            <v-tooltip bottom transition="slide-y-transition">
               <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="fechar" v-on="on">
                     <v-icon large>bi-x</v-icon>
                  </v-btn>
               </template>
               <span>Fechar</span>
            </v-tooltip>
         </v-card-title>

         <v-card-text class="pb-2">
            <v-img src="@/assets/MaeElenials/plano-desenvolvimento-individual.svg" />
         </v-card-text>
      </v-card>
   </v-dialog>
</template>

<script>
import eventBus from '@/event-bus';

export default {
   name: 'DialogPlanoDesenvolvimentoIndividual',
   data: () => ({
      dialog: false
   }),
   mounted() {
      eventBus.$on('abrir-plano-desenvolvimento-individual-mae-elenials', this.abrir);
   },
   beforeDestroy() {
      eventBus.$off('abrir-plano-desenvolvimento-individual-mae-elenials');
   },
   methods: {
      abrir() {
         this.dialog = true;
      },
      fechar() {
         this.dialog = false;
      }
   }
};
</script>
