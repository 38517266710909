<template>
   <div class="text-left">
      <div class="text-body-2 text-caption mt-2 text-left">
         Nós sabemos: voltar para o trabalho após a licença-maternidade é um dos momentos mais difíceis para uma mãe.
      </div>
      <div class="text-caption">
         Isso ocorre porque o desejo de permanecer ao lado do bebê, acompanhando cada segundo do seu desenvolvimento e
         trazendo bem-estar e acolhimento nesse mundo no qual ele foi recentemente inserido, entra em conflito com o de
         voltar a uma rotina de desenvolvimento da carreira profissional.
      </div>
      <div class="text-caption mt-4">
         Para ajudar a amenizar esse momento, confira algumas dicas para retornar à rotina de trabalho e se adaptar sem
         que isso signifique uma experiência traumatizante para você ou para o bebê:
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
            1- Escolha com quem deixar o bebê:
         </strong>
      </div>
      <div class="text-caption">
         não importa se é o pai, os avós, a babá ou a creche — basta ser alguém de confiança e responsável para que você
         não esteja preocupada durante a jornada de trabalho.
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
            2- Transmita tranquilidade:
         </strong>
      </div>
      <div class="text-caption">
         provavelmente o bebê sinta falta da sua presença e demonstre isso estando mais carente, choroso e resistente ao
         sono, mas é uma reação passageira e vai necessitar que você o acolha com paciência e transmita a ele
         tranquilidade.
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
            3- Organize-se no trabalho:
         </strong>
      </div>
      <div class="text-caption">
         no retorno, converse com os superiores para alinhar expectativas de ambos os lados, busque não misturar
         questões pessoais e profissionais e, caso sinta a necessidade, converse com o setor de recursos humanos sobre
         estratégias para facilitar esse período de retorno .
      </div>
      <div class="text-caption mt-4">
         Além dessas dicas, um passo muito importante é encarar que todos os receios e expectativas em relação a voltar
         da licença maternidade é algo absolutamente natural. Dessa forma, vai ser mais fácil lidar com uma realidade na
         qual você desempenha o papel de mulher, de mãe e de profissional de forma excelente — e nenhuma dessas
         realidades precisa subtrair a outra, certo?
      </div>
      <div class="text-caption mt-4">
         Para receber mais dicas, acesse
         <a @click="abrirUrlMaeElenials"><u>www.maellennials.com</u></a> e faça parte da nossa comunidade.
      </div>
   </div>
</template>

<script>
export default {
   name: 'Reonboarding',
   methods: {
      abrirUrlMaeElenials() {
         window.open('https://www.maellennials.com');
      }
   }
};
</script>
