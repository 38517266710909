<template>
   <v-app>
      <component :is="layout">
         <router-view />
      </component>
   </v-app>
</template>

<script>
const defaultLayout = 'base';

export default {
   name: 'App',

   computed: {
      layout() {
         return `${this.$route.meta.layout || defaultLayout}-wrapper`;
      }
   }
};
</script>
<style lang="scss">
@import './scss/custom.scss';
</style>
