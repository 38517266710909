<template>
   <div>
      <Video :fim-url-video-youtube="fimUrlVideoYoutube" />
      <p v-if="mostrarTextoPrincipal" class="mt-2 text-caption mx-4 texto-stepper">
         {{ textoPrincipal }}
      </p>
      <div v-if="ehVerMais" class="text-left mt-4 mb-4 mx-4 pl-1">
         <slot name="verMais" />
      </div>
      <div class="d-flex justify-end mx-4 mb-2">
         <v-icon class="mr-1" color="primary" small>{{ ehVerMais ? 'bi-dash-square' : 'bi-plus-square' }}</v-icon>
         <a @click="toggleEhVerMais">
            <u>{{ ehVerMais ? 'Ver menos' : 'Ver mais' }}</u>
         </a>
      </div>
      <div class="d-flex justify-center mt-4 mb-4 mx-4">
         <slot />
      </div>
   </div>
</template>

<script>
import Video from '@/components/MaeElenials/Video';

export default {
   name: 'ConteudoStepperPapoRetoPainel',
   components: { Video },
   props: {
      textoPrincipal: { type: String, default: null },
      naoMostrarTextoPrincipalComVermais: { type: Boolean, default: false },
      fimUrlVideoYoutube: { type: String, default: null }
   },
   data: () => ({
      ehVerMais: false
   }),
   computed: {
      mostrarTextoPrincipal() {
         return !this.ehVerMais;
      }
   },
   methods: {
      toggleEhVerMais() {
         this.ehVerMais = !this.ehVerMais;
      }
   }
};
</script>

<style></style>
