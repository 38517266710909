<template>
   <div class="mx-6">
      <v-img src="@/assets/MaeElenials/gestacao-com-a-carreira.svg">
         <div class="text-subtitle-1 font-weight-bold imagem-header mx-4 text-center">
            Como conciliar a gestação com a carreira
         </div>
      </v-img>
      <div class="text-left">
         <div class="text-body-2 text-caption mt-2 text-left">
            Já ficou no passado a ideia de que uma mulher que pretende ter filhos precisa ser uma perfeita dona de casa
            - a não ser que seja uma decisão própria dessa mulher. Atualmente, uma grande parte das mulheres opta por
            conciliar a gestação com a carreira profissional, muitas vezes como uma estratégia para não fazerem mudanças
            bruscas na rotina antes do nascimento do bebê.
         </div>
         <div class="text-caption mt-4">
            E se não houver nenhuma restrição médica em relação a continuar com suas atividades de trabalho, damos
            algumas dicas de como conciliar a gestação com a rotina de trabalho:
         </div>
         <div class="text-caption mt-4">
            <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
               1- Mapeie todas as circunstâncias de risco do seu trabalho:
            </strong>
            antes de tudo, é necessário pedir autorização ao seu obstetra, que precisa analisar se sua rotina não vai
            interferir na sua saúde ou na saúde do bebê. Por isso, comente se você trabalha em jornadas ou turnos muito
            longos, se é um trabalho que exige esforço físico, se precisa ficar muito tempo em pé ou caminhando, etc.
         </div>
         <div class="text-caption mt-4">
            <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
               2- Adote uma postura cômoda na hora de trabalhar:
            </strong>
            pode ser na especialização em uma área específica, um curso para aprender um novo idioma, etc. Foque em
            temas que ajudem a abrir um leque dentro da sua área.
         </div>
         <div class="text-caption mt-4">
            <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
               3- Lembre-se de que deve se hidratar constantemente:
            </strong>
            troque qualquer bebida estimulante (como o café ou aquele chazinho) por água e, quando bater a vontade de
            usar o banheiro para fazer xixi, siga a vontade para não reter líquidos.
         </div>
         <div class="text-caption mt-4">
            <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
               4- Vista-se de maneira confortável:
            </strong>
            sabemos que um look mais sofisticado para o trabalho nos empodera, mas, durante a gestação, opte por roupas
            e sapatos mais confortáveis, principalmente para poder estar mais em movimento (de maneira moderada, claro)
            e, dessa forma, evitar o inchaço nas articulações.
         </div>
         <div class="text-caption mt-4">
            <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
               5- Tenha cuidado ao trabalhar ao ar livre:
            </strong>
            a exposição ao calor por muito tempo pode ser prejudicial, por isso, prefira manter-se em ambientes com
            temperatura não tão alta.
         </div>
         <div class="text-caption mt-4">
            <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
               6- E, claro, adote uma rotina saudável:
            </strong>
            ocê já escutou isso mil vezes, mas é sempre importante repetir! Opte por refeições mais saudáveis, elimine
            hábitos tóxicos como fumar ou beber álcool e lembre-se de descansar bem para cuidar da sua saúde e da saúde
            do seu bebê.
         </div>
         <div class="text-caption mt-4">
            Para receber mais dicas, acesse
            <a @click="abrirUrlMaeElenials"><u>www.maellennials.com</u></a> e faça parte da nossa comunidade.
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'GestacaoComACarreira',
   methods: {
      abrirUrlMaeElenials() {
         window.open('https://www.maellennials.com');
      }
   }
};
</script>
