<template>
   <v-radio-group v-model="internalValue" class="d-flex" :rules="rules">
      <template v-for="(opcao, index) in opcoesRadio">
         <v-radio :key="index" :value="opcao.value">
            <template #label>
               <div class="d-flex text-subtitle-2">
                  <strong
                     v-if="ehRadioComOpcoesCustomizadas"
                     class="titulo-card-detalhe-papo-reto font-weight-bold align-self-center"
                  >
                     {{ opcao.textoInicio }}
                  </strong>
                  <strong
                     v-if="ehRadioComOpcoesCustomizadas"
                     class="titulo-card-detalhe-papo-reto font-weight-bold ml-1 mr-1 align-self-center"
                  >
                     {{ opcao.separador }}
                  </strong>
                  <div>
                     {{ opcao.label }}
                  </div>
               </div>
            </template>
         </v-radio>
      </template>
   </v-radio-group>
</template>

<script>
export default {
   name: 'RadioGroup',
   props: {
      value: { type: String, default: '' },
      rules: { type: Array, default: () => [] },
      ehRadioComOpcoesCustomizadas: { type: Boolean, default: false },
      opcoesRadio: { type: Array, default: () => [] }
   },
   computed: {
      internalValue: {
         get() {
            return this.value;
         },
         set(value) {
            this.$emit('input', value);
         }
      },
      regras() {
         return [...this.rules];
      }
   }
};
</script>
