import Vue from 'vue';
import VueRouter from 'vue-router';
import InicioJornada from '@/views/MaeElenials/InicioJornada';
import PapoRetoMaternidade from '@/views/MaeElenials/PapoRetoMaternidade';
import GestacaoComACarreira from '@/views/MaeElenials/Artigos/GestacaoComACarreira';
import ComoEvitarBurnout from '@/views/MaeElenials/Artigos/ComoEvitarBurnout';
import FormularioQuiz from '@/views/MaeElenials/Quiz/Formulario';

Vue.use(VueRouter);

const routes = [
   {
      path: '/',
      name: 'InicioJornada',
      meta: { layout: 'mae-elenials' },
      component: InicioJornada
   },
   {
      path: '/maeelenials/paporetomaternidade',
      name: 'InicioJornada',
      meta: { layout: 'mae-elenials' },
      component: PapoRetoMaternidade
   },
   {
      path: '/maeelenials/artigo/gestacaocomcarreira',
      name: 'GestacaoComCarreira',
      meta: { layout: 'mae-elenials' },
      component: GestacaoComACarreira
   },
   {
      path: '/maeelenials/artigo/evitarburnout',
      name: 'ComoEvitarBurnout',
      meta: { layout: 'mae-elenials' },
      component: ComoEvitarBurnout
   },
   {
      path: '/maeelenials/quiz/formulario',
      name: 'ComoEvitarBurnout',
      meta: { layout: 'mae-elenials' },
      component: FormularioQuiz
   }
];

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
});

export default router;
