<template>
   <div class="mx-6">
      <v-img src="@/assets/MaeElenials/como-evitar-burnout.svg">
         <div class="text-subtitle-1 font-weight-bold imagem-header mx-4 text-center">
            Como evitar um burnout pelo acúmulo de tarefas
         </div>
      </v-img>
      <div class="text-left">
         <div class="text-body-2 text-caption mt-2 text-left">
            Quando estamos imersas na rotina de ser mulher, mãe e profissional é difícil notar alguns sinais que nosso
            organismo envia: exaustão, dores de cabeça, irritabilidade e até mesmo uma sensação de fracasso que perdura
            mais do que o normal.
         </div>
         <div class="text-caption mt-4">
            Mas quando isso deixa de ser simples cansaço para se tornar em algo mais sério, que necessita ajuda de
            profissionais?
         </div>
         <div class="text-caption mt-4">
            A Síndrome de Burnout Materno está associada ao esgotamento devido ao trabalho em excesso - e quando falamos
            em “trabalho”, nos referimos também os cuidados com os filhos e com a casa, num malabarismo que, se muito
            intenso, é pouco saudável para as mulheres.
         </div>
         <div class="text-caption mt-4">
            O diagnóstico pode ser dado por um psicólogo perinatal ou psiquiatra perinatal, após uma rígida avaliação
            dos sintomas da paciente e de como ocorreu todo processo da maternidade em sua vida.
         </div>
         <div class="text-caption mt-4">
            Para evitar chegar a um quadro crítico de esgotamento pelo acúmulo de tarefas, sugerimos algumas medidas
            práticas no dia a dia:
         </div>
         <div class="text-caption mt-4">
            <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold"> 1. </strong>
            Ter uma rede de apoio, com pessoas de confiança e com quem você possa contar em momentos que necessite
            ajuda.
         </div>
         <div class="text-caption mt-4">
            <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold"> 2. </strong>
            Conversar com outras mães, que possuem a mesma vivência que você, pode servir para quem busca apoio e
            empatia.
         </div>
         <div class="text-caption mt-4">
            <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold"> 3. </strong>
            Saber pedir e aceitar ajuda, sem querer carregar o mundo nas costas, assumindo que não é necessário dar
            conta de tudo sozinha para ser considerada uma boa mãe.
         </div>
         <div class="text-caption mt-4">
            <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold"> 4. </strong>
            Reservar na agenda um tempo só pra você, o que envolve sair de vez em quando, dar-se mimos, cuidar da saúde
            e da autoestima e conversar sobre outros temas que não tenham a ver com a maternidade.
         </div>

         <div class="text-caption mt-4">
            Lembre-se que coisas simples no cotidiano podem fazer toda a diferença e, antes de esgotar, que você possa
            transbordar: de auto acolhimento e auto gentileza.
         </div>

         <div class="text-caption mt-4">
            Para receber mais dicas, acesse
            <a @click="abrirUrlMaeElenials"><u>www.maellennials.com</u></a> e faça parte da nossa comunidade.
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'ComoEvitarBurnout',
   methods: {
      abrirUrlMaeElenials() {
         window.open('https://www.maellennials.com');
      }
   }
};
</script>
