<template>
   <v-card outlined rounded="lg" elevation="4" max-width="290" @click="irParaRedirecionamento">
      <v-card-text class="conteudo-card-detalhe-papo-reto">
         <v-list-item class="item-card-papo-reto">
            <v-list-item-avatar tile size="42" rounded class="align-self-center avatar-card-detalhe">
               <v-chip color="primary">
                  <v-icon color="white">{{ icone }}</v-icon>
               </v-chip>
            </v-list-item-avatar>
            <v-list-item-content class="conteudo-card-detalhe-papo-reto">
               <div class="text-body-2 font-weight-bold titulo-card-detalhe-papo-reto mt-1">
                  {{ titulo }}
               </div>
               <p v-if="texto" class="text-caption texto-stepper">
                  {{ texto }}
               </p>
               <div class="d-flex justify-end mt-1 mb-1">
                  <v-chip color="primary" small>
                     {{ tipo }}
                  </v-chip>
               </div>
            </v-list-item-content>
         </v-list-item>
      </v-card-text>
   </v-card>
</template>

<script>
export default {
   name: 'CardDetalheStepperPapoReto',
   props: {
      titulo: { type: String, default: null },
      texto: { type: String, default: null },
      tipo: { type: String, default: null },
      icone: { type: String, default: 'bi-file-earmark-text' },
      urlDirecionamento: { type: String, default: '' },
      acaoDirecionamento: { type: Function, default: null }
   },
   methods: {
      irParaRedirecionamento() {
         if (this.acaoDirecionamento) return this.acaoDirecionamento();

         const ehDirecionamentoYoutube = this.urlDirecionamento.includes('youtu.be');
         if (ehDirecionamentoYoutube) window.open(this.urlDirecionamento);
         else if (this.urlDirecionamento) this.$router.push(this.urlDirecionamento);
      }
   }
};
</script>
