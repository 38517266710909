<template>
   <v-expansion-panel>
      <v-expansion-panel-header class="white--text" color="#A157A8">
         <template v-slot:actions>
            <v-icon color="white"> $expand </v-icon>
         </template>
         <div class="d-flex">
            <div class="font-weight-bold align-self-center">
               <v-chip color="#EDDAF5" text-color="#A157A8">
                  {{ etapa }}
               </v-chip>
            </div>
            <div class="ml-4 text-caption align-self-center">
               {{ titulo }}
            </div>
         </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="conteudo-expasion-papo-reto-maternidade" eager>
         <slot />
      </v-expansion-panel-content>
   </v-expansion-panel>
</template>

<script>
export default {
   name: 'PapoRetoPainel',
   props: {
      etapa: { type: Number, default: 1 },
      titulo: { type: String, default: null }
   }
};
</script>
