export default {
   opcoesRadio1: [
      {
         textoInicio: 'A',
         separador: '-',
         value: 'a',
         label: 'Sim, já está tudo organizado/programado, já até preparei uma listinha com algumas dicas de cuidados com o bebê para quando eu estiver trabalhando.'
      },
      {
         textoInicio: 'B',
         separador: '-',
         value: 'b',
         label: 'Ainda não, mas já estou pesquisando opções para tomar minha decisão.'
      },
      {
         textoInicio: 'C',
         separador: '-',
         value: 'c',
         label: 'Estou postergando a decisão.'
      }
   ],
   opcoesRadio2: [
      {
         textoInicio: 'A',
         separador: '-',
         value: 'a',
         label: 'Flexível. Já arrisquei até mesmo sair umas horinhas e deixá-lo sob os cuidados de alguém próximo.'
      },
      {
         textoInicio: 'B',
         separador: '-',
         value: 'b',
         label: 'Restrito aos cuidados do bebê, mas quero começar a ter mais flexibilidade.'
      },
      {
         textoInicio: 'C',
         separador: '-',
         value: 'c',
         label: '100% voltado para o meu bebê. Só desgrudo pra ir ao banheiro.'
      }
   ],
   opcoesRadio3: [
      {
         textoInicio: 'A',
         separador: '-',
         value: 'a',
         label: 'De 1 a 2, sinto saudades da minha rotina como era antes.'
      },
      {
         textoInicio: 'B',
         separador: '-',
         value: 'b',
         label: 'De 3 a 4, sinto que falta algo na minha rotina.'
      },
      {
         textoInicio: 'C',
         separador: '-',
         value: 'c',
         label: '5, me sinto totalmente plena em poder me dedicar com exclusividade ao bebê.'
      }
   ],
   opcoesRadio4: [
      {
         textoInicio: 'A',
         separador: '-',
         value: 'a',
         label: 'Fica super animada com a possibilidade de retornar a sua velha rotina com o diferencial de que agora você é mãe.'
      },
      {
         textoInicio: 'B',
         separador: '-',
         value: 'b',
         label: 'Prefere não pensar muito nesse momento.'
      },
      {
         textoInicio: 'C',
         separador: '-',
         value: 'c',
         label: 'Fica de cabelos em pé! Será que o bebê não é muito pequeno pra se separar de você?'
      }
   ],
   opcoesRadio5: [
      {
         textoInicio: 'A',
         separador: '-',
         value: 'a',
         label: 'Sim, ele já está até bem acostumado com alguns parentes/amigos/babá.'
      },
      {
         textoInicio: 'B',
         separador: '-',
         value: 'b',
         label: 'Na medida do possível, mas reservo mais tempo a sós com ele.'
      },
      {
         textoInicio: 'C',
         separador: '-',
         value: 'c',
         label: 'Não, por enquanto ele teve pouco contato com outras pessoas de fora do nosso núcleo familiar.'
      }
   ]
};
