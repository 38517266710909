<template>
   <div class="text-left">
      <div class="text-body-2 text-caption mt-2 text-left">
         É um fato: enquanto empresas retrógradas ainda veem a maternidade como um tabu, empresas visionárias e que
         buscam transformar seus negócios cada vez mais sustentáveis se beneficiam de todas as competências adquiridas
         pelas profissionais através da experiência intensa que é a maternidade.
      </div>
      <div class="text-caption mt-4">
         Quer conhecer algumas das habilidades que mulheres desenvolvem após se tornarem mães e que podem ser aplicadas
         para turbinar a carreira? A gente conta pra você:
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold"> 1.PLANEJAMENTO: </strong>
      </div>
      <div class="text-caption">
         ser mãe é lidar com logísticas das mais diversas - e nada como um bom planejamento e organização para conciliar
         os horários dos filhos com os seus, sem conflitos na agenda. Imagine isso aplicado à gestão de equipes e
         projetos?
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold"> 2.EMPATIA: </strong>
      </div>
      <div class="text-caption">
         se colocar no lugar do outro é fácil para alguém que, depois que o filho nasceu, deixou de ser o centro da sua
         própria atenção e aprendeu a identificar e sanar as necessidades do próximo.
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold"> 3.COMPROMETIMENTO: </strong>
      </div>
      <div class="text-caption">
         um dos maiores amadurecimentos da maternidade vem com o compromisso eterno de ser comprometido com o filho,
         isso torna a profissional mais responsável e engajada com os projetos sob sua tutela.
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
            4. TRABALHO EM EQUIPE:
         </strong>
      </div>
      <div class="text-caption">
         saber delegar tarefas e gerenciar pessoas diferentes, que muitas vezes entram em conflito - como no caso dos
         irmãos - é mais uma habilidade da lista.
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
            5. E, CLARO, INTELIGÊNCIA EMOCIONAL:
         </strong>
      </div>
      <div class="text-caption">
         cuidar de uma criança (que vez ou outra faz birra ou apronta alguma arte, etc) ajuda a desenvolver a
         inteligência emocional para não perder o controle e a paciência diante das situações mais estressantes do
         cotidiano.
      </div>
      <div class="text-caption mt-4">
         Essas são só algumas das competências adquiridas pelas mães. E você, o que aprendeu durante o processo? Liste
         num papel todas as novas habilidades e reflita sobre como a experiência da maternidade pode sim ser
         potencializadora de carreiras.
      </div>
      <div class="text-caption mt-4">
         Para receber mais dicas, acesse
         <a @click="abrirUrlMaeElenials"><u>www.maellennials.com</u></a> e faça parte da nossa comunidade.
      </div>
   </div>
</template>

<script>
export default {
   name: 'SuperMae',
   methods: {
      abrirUrlMaeElenials() {
         window.open('https://www.maellennials.com');
      }
   }
};
</script>
