<template>
   <div class="text-left">
      <div class="text-body-2 text-caption mt-2 text-left">
         Com a chegada dos filhos, nossas prioridades mudam: muitas mães querem encontrar um trabalho que permita mais
         tempo de qualidade com as crianças ou cargos mais altos que agreguem um salário mais interessante para arcar os
         custos da escola e por aí vai.
      </div>
      <div class="text-caption mt-4">
         Independente de qual seja a sua nova meta, para alcançá-la é necessário um plano de ação. Que tal desenvolvê-lo
         e, assim, ter um melhor direcionamento dos seus próximos passos na carreira?
      </div>
      <div class="text-caption mt-4">Aqui vão algumas dicas:</div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
            1. Entenda onde você está agora:
         </strong>
      </div>
      <div class="text-caption">
         conhecer bem seu ponto de partida é importante para definir aonde você quer chegar. Analise objetivamente seu
         currículo e liste quais são os pontos positivos e negativos, o que você pode aperfeiçoar e o que falta para
         esse currículo parecer ainda mais atrativo para o mercado.
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
            2. Invista na sua educação:
         </strong>
      </div>
      <div class="text-caption">
         pode ser na especialização em uma área específica, um curso para aprender um novo idioma, etc. Foque em temas
         que ajudem a abrir um leque dentro da sua área.
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold"> 3. Dedique-se: </strong>
      </div>
      <div class="text-caption">
         não adianta ter um plano bem traçado se você não puder ter disciplina e se dedicar a cumprir os passos.
         Imprevistos ocorrem, variáveis são constantes, mas é necessário se certificar de cumprir o planejamento mesmo
         que de forma mais lenta do que você esperava.
      </div>
      <div class="text-caption mt-4">
         Deu pra entender a importância desse plano? Então, mãos à obra e é hora de preparar o seu para uma trajetória
         de sucesso!
      </div>
      <div class="text-caption mt-4">
         Para receber mais dicas, acesse
         <a @click="abrirUrlMaeElenials"><u>www.maellennials.com</u></a> e faça parte da nossa comunidade.
      </div>
   </div>
</template>

<script>
export default {
   name: 'VemComAMae',
   methods: {
      abrirUrlMaeElenials() {
         window.open('https://www.maellennials.com');
      }
   }
};
</script>
