<template>
   <v-card outlined rounded="lg" elevation="4" :loading="loading" :disabled="loading">
      <v-card-text class="conteudo-card-papo-reto-maternidade">
         <div class="d-flex flex-no-wrap justify-space-between">
            <v-avatar size="140" class="align-self-center">
               <v-img src="@/assets/MaeElenials/papo-reto-maternidade.svg" contain />
            </v-avatar>
            <div class="text-left align-self-center">
               <div class="titulo-card text-h7 font-weight-bold">Papo reto sobre Maternidade</div>
               <p class="mt-1 text-caption texto-card">
                  Série de dicas e experiências sobre como ser mãe no mercado de trabalho...
               </p>
            </div>
         </div>
      </v-card-text>
      <v-card-actions class="actions-papo-reto-maternidade-card">
         <v-btn color="#00BD5B" :disabled="loading" block class="white--text" to="/maeelenials/paporetomaternidade">
            Experimentar
         </v-btn>
      </v-card-actions>
   </v-card>
</template>

<script>
export default {
   name: 'CardPapoRetoMaternidade',
   data: () => ({
      loading: false
   })
};
</script>
