<template>
   <v-responsive :aspect-ratio="16 / 9">
      <iframe
         id="video-mae-elenials-iframe"
         title="Vídeo Youtube"
         frameborder="0"
         width="100%"
         height="100%"
         :src="urlVideoYoutube"
      />
   </v-responsive>
</template>

<script>
export default {
   name: 'Video',
   props: {
      fimUrlVideoYoutube: { type: String, default: '_SbRrHcJh2o' }
   },
   computed: {
      urlVideoYoutube() {
         return `https://www.youtube.com/embed/${this.fimUrlVideoYoutube}`;
      }
   }
};
</script>
