import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/es5/locale/pt';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

const tema = {
   primary: '#A157A8',
   secondary: '#00BD5B',
   accent: '#00B0BD',
   error: '#FF5252',
   info: '#2196F3',
   success: '#4CAF50',
   warning: '#FFC107'
};

export default new Vuetify({
   theme: {
      options: {
         customProperties: true
      },
      themes: {
         light: tema,
         dark: tema
      }
   },
   lang: {
      locales: { 'pt-BR': pt, 'es-CL': es },
      current: 'pt-BR'
   },
   icons: {
      iconfont: 'mdi'
   }
});
