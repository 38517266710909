<template>
   <div class="mx-6 mt-2">
      <v-img src="@/assets/MaeElenials/quiz.svg">
         <div class="text-subtitle-1 font-weight-bold imagem-header mx-4 text-center pa-2">Quiz</div>
      </v-img>
      <div v-if="!resultado" class="text-left">
         <v-form>
            <v-row>
               <v-col>
                  <div class="text-caption mt-4">
                     <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
                        1- Você já tem decidido quem cuidará do bebê quando você retornar à rotina de trabalho?
                     </strong>
                  </div>
                  <div class="mt-2">
                     <RadioGroup
                        v-model="entidade.pergunta1"
                        :opcoes-radio="opcoesFormulario.opcoesRadio1"
                        eh-radio-com-opcoes-customizadas
                        class="mt-4"
                     />

                     <div class="text-caption mt-4">
                        <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
                           2- Atualmente o seu dia a dia é…
                        </strong>
                     </div>

                     <RadioGroup
                        v-model="entidade.pergunta2"
                        :opcoes-radio="opcoesFormulario.opcoesRadio2"
                        eh-radio-com-opcoes-customizadas
                        class="mt-4"
                     />

                     <div class="text-caption mt-4">
                        <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
                           3- Numa escala de 1 a 5, como você se sente, em geral?
                        </strong>
                     </div>

                     <RadioGroup
                        v-model="entidade.pergunta3"
                        :opcoes-radio="opcoesFormulario.opcoesRadio3"
                        eh-radio-com-opcoes-customizadas
                        class="mt-4"
                     />

                     <div class="text-caption mt-4">
                        <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
                           4- Quando você pensa em voltar à rotina de trabalho e deixar o bebê sob tutela do pai, dos
                           avós, da babá ou na creche, você:
                        </strong>
                     </div>

                     <RadioGroup
                        v-model="entidade.pergunta4"
                        :opcoes-radio="opcoesFormulario.opcoesRadio4"
                        eh-radio-com-opcoes-customizadas
                        class="mt-4"
                     />

                     <div class="text-caption mt-4">
                        <strong class="text-subtitle-2 titulo-card-detalhe-papo-reto font-weight-bold">
                           5- Você já introduz novos rostos na rotina do bebê, para que ele se habitue a estar com
                           outras pessoas?
                        </strong>
                     </div>

                     <RadioGroup
                        v-model="entidade.pergunta5"
                        :opcoes-radio="opcoesFormulario.opcoesRadio5"
                        eh-radio-com-opcoes-customizadas
                        class="mt-4"
                     />
                  </div>
               </v-col>
            </v-row>
         </v-form>
         <div>
            <v-btn
               large
               block
               color="#00BD5B"
               :disabled="validacaoFormulario"
               :loading="loading"
               class="white--text"
               @click="enviar"
            >
               ENVIAR
            </v-btn>
         </div>
      </div>
      <div v-else-if="resultado.status === 'MAIORIA_A'">
         <div class="text-h6 font-weight-bold mt-2">Resultado:</div>
         <div class="text-h6 font-weight-bold primary--text">Maioria A</div>
         <div class="text-center mt-2">
            <div class="text-center">
               <img src="@/assets/MaeElenials/quiz-sucesso.svg" width="120" />
            </div>
            <div class="text-h6 font-weight-bold sucess--text mt-2 sucesso-resultado-quiz">Prontíssima</div>
         </div>
         <div class="text-center text-caption mt-4">
            Você está ávida por retornar à antiga rotina e não tem nada de errado com isso - afinal, uma mãe feliz
            reflete na alegria do bebê também. Certificando-se de deixar o bebê sob os cuidados de alguém preparado para
            essa responsabilidade, você já pode iniciar a readaptação gradualmente ao trabalho.
         </div>
      </div>
      <div v-else-if="resultado.status === 'MAIORIA_B'">
         <div class="text-h6 font-weight-bold mt-2">Resultado:</div>
         <div class="text-h6 font-weight-bold primary--text">Maioria B</div>
         <div class="text-center mt-2">
            <div class="text-center">
               <img src="@/assets/MaeElenials/quiz-duvida.svg" width="120" />
            </div>
            <div class="text-h6 font-weight-bold sucess--text mt-2 prontissima-resultado-quiz">
               Hum... ainda na dúvida!
            </div>
         </div>
         <div class="text-center text-caption mt-4">
            Você ainda tem sentimentos dúbios em relação ao retorno ao trabalho. Isso é normal principalmente porque
            sofremos de “culpa materna” e é importante entender que ser uma boa mãe não é necessariamente desempenhar
            esse papel 24 horas por dia. Caso se sinta mais à vontade, converse com o setor de Recursos Humanos para
            avaliar a possibilidade de retornar remotamente ou de maneira híbrida.
         </div>
      </div>
      <div v-else>
         <div class="text-h6 font-weight-bold mt-2">Resultado:</div>
         <div class="text-h6 font-weight-bold primary--text">Maioria C</div>
         <div class="text-center mt-2">
            <div class="text-center">
               <img src="@/assets/MaeElenials/quiz-relogio.svg" width="120" />
            </div>
            <div class="text-h6 font-weight-bold sucess--text mt-2 so-mais-alguns-minutinhos-quiz">
               Só mais 5 minutinhos...
            </div>
         </div>
         <div class="text-center text-caption mt-4">
            Você está desfrutando ao máximo em acompanhar cada segundo dessa fase do bebê e está tudo bem também.
            Converse com sua família e, depois, no seu trabalho, sobre a possibilidade de estender a licença-maternidade
            por mais tempo, mas lembre-se de tirar um tempinho no dia a dia para você, afinal, o autocuidado também é
            importante.
         </div>
      </div>
   </div>
</template>

<script>
import RadioGroup from '@/components/Campos/RadioGroup';
import OpcoesFormulario from './OpcoesFormulario';

export default {
   name: 'FormularioQuiz',
   components: { RadioGroup },
   data: () => ({
      entidade: {},
      loading: false,
      opcoesFormulario: OpcoesFormulario,
      resultado: null
   }),
   computed: {
      validacaoFormulario() {
         const ehTodosCamposPreenchidos =
            !!this.entidade.pergunta1 &&
            !!this.entidade.pergunta2 &&
            !!this.entidade.pergunta3 &&
            !!this.entidade.pergunta4 &&
            !!this.entidade.pergunta5;
         return !ehTodosCamposPreenchidos;
      }
   },
   methods: {
      abrirUrlMaeElenials() {
         window.open('https://www.maellennials.com');
      },
      gerarResultadoFormulario() {
         const resultadoEncontrado = this.gerarResultadoDoQuiz();

         switch (resultadoEncontrado.identificador) {
            case 'a':
               this.resultado = { status: 'MAIORIA_A' };
               break;
            case 'b':
               this.resultado = { status: 'MAIORIA_B' };
               break;
            case 'c':
               this.resultado = { status: 'MAIORIA_C' };
               break;
         }
      },
      gerarResultadoDoQuiz() {
         let resultados = [
            { identificador: 'a', total: 0, peso: 3 },
            { identificador: 'b', total: 0, peso: 2 },
            { identificador: 'c', total: 0, peso: 1 }
         ];
         Object.keys(this.entidade).forEach((key) => {
            const opcao = this.entidade[key];
            const indexOpcao = resultados.findIndex((result) => result.identificador === opcao);
            resultados[indexOpcao].total += 1;
         });
         resultados = resultados.sort((a, b) => (a.total > b.total ? -1 : 1));
         resultados = resultados.sort((a, b) => (a.total === b.total ? a.peso < b.peso && -1 : 1));
         return resultados[0];
      },
      enviar() {
         this.gerarResultadoFormulario();
      }
   }
};
</script>
