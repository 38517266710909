<template>
   <div class="text-left">
      <div class="text-caption mt-2">
         Imagine a cena: durante a entrevista, a candidata estava indo muito bem, cumpria com todos os requisitos da
         vaga e conquistou a simpatia dos recrutadores...até ela mencionar que tinha filhos ou que estava esperando um
         bebê. A partir daí, o tom da entrevista mudou e, além de parecerem haver perdido o interesse, os recrutadores
         fizeram perguntas bem pessoais sobre o planejamento familiar da candidata, tudo para, no final, dizerem que o
         perfil dela não está mais alinhado ao que a empresa busca. A cena pode parecer drástica, mas é a realidade de
         muitas e muitas candidatas que são ou vão ser mães ao buscarem trabalho.
      </div>
      <div class="text-caption mt-4">
         Mas se você já passou por algo assim, saiba que uma empresa não pode discriminar uma candidata pelo simples
         fato dela ser mãe, principalmente porque os cuidados com filhos não deviam ser exclusivos das mães e sim
         divididos com o pai da criança.
      </div>
      <div class="text-caption mt-4">
         É por isso que perguntas de cunho pessoal não devem ocorrer em entrevistas de emprego, por mais que o objetivo
         seja conhecer a fundo os candidatos, por meio de suas habilidades e experiências. Além disso, não cabe aos
         recrutadores interferirem em decisões pessoais.
      </div>
      <div class="text-caption mt-4">
         Aliás, essa abordagem invasiva contraria o princípio da equidade de gênero. O artigo 5 da Constituição
         Brasileira, que traz à tona a igualdade de direitos, da privacidade, inviolabilidade da honra, vida privada e
         intimidade.
      </div>
      <div class="text-caption mt-4">
         Por isso, em entrevistas de emprego, você pode adotar alguns dos seguintes posicionamentos, caso os
         recrutadores questionem se você tem filhos e sobre quem cuidará deles caso seja selecionada:
      </div>
      <div class="text-caption mt-4">
         “Sim, tenho filhos e conto com a ajuda do pai/familiares/babá para cuidar deles.”
      </div>
      <div class="text-caption">
         “Sim, no entanto minha rotina doméstica nunca interferiu na minha entrega de resultados.”
      </div>
      <div class="text-caption">
         “Prefiro que a entrevista foque somente em tópicos relacionados ao meu histórico profissional, você se importa
         se seguimos assim?”
      </div>
      <div class="text-caption mt-4">
         O importante é ser honesta e demonstrar segurança nas suas respostas, de forma educada.
      </div>
      <div class="text-caption">
         Afinal, quando o seu currículo fala por você, merece que toda forma de discriminação se cale.
      </div>
      <div class="text-caption mt-4">
         Para receber mais dicas, acesse
         <a @click="abrirUrlMaeElenials"><u>www.maellennials.com</u></a> e faça parte da nossa comunidade.
      </div>
   </div>
</template>

<script>
export default {
   name: 'ComoContarEmEntrevistas',
   methods: {
      abrirUrlMaeElenials() {
         window.open('https://www.maellennials.com');
      }
   }
};
</script>
