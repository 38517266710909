<template>
   <div class="text-center mx-6">
      <div class="text-h6 black--text text-left">Papo Reto sobre Maternidade</div>
      <v-expansion-panels class="mt-2">
         <PapoRetoPainel :etapa="1" titulo="Vou ser mãe. E agora?">
            <ConteudoStepperPapoRetoPainel
               texto-principal="Se tem um tema que, por mais que a gente se prepare, sempre vem com uma carga de emoções nem sempre
            atreladas somente à alegria, é descobrir aquele positivo no teste de gravidez."
               fim-url-video-youtube="QD270uL-tTA"
            >
               <CardDetalheStepperPapoReto
                  titulo="Como conciliar a gestação com a carreira"
                  tipo="ARTIGO"
                  texto="Já ficou no passado a ideia de que uma mulher que pretende ter filhos..."
                  url-direcionamento="/maeelenials/artigo/gestacaocomcarreira"
               />
               <template #verMais>
                  <VouSerMaeEAgora />
               </template>
            </ConteudoStepperPapoRetoPainel>
         </PapoRetoPainel>
         <PapoRetoPainel :etapa="2" titulo="Como contar em entrevistas que sou/quero ser mãe?" class="mt-2">
            <ConteudoStepperPapoRetoPainel
               texto-principal="Imagine a cena: durante a entrevista, a candidata estava indo muito bem, cumpria com todos os requisitos da vaga e conquistou a simpatia dos recrutadores...até ela mencionar que tinha filhos."
               fim-url-video-youtube="3sNSMJZQ_AM"
            >
               <CardDetalheStepperPapoReto
                  titulo="Fui contratada grávida"
                  tipo="PODCAST"
                  texto="Conheça a história de Flávia, profissional da educação e mãe... "
                  icone="bi-mic"
                  url-direcionamento="https://youtu.be/_MoNjhxiot8"
               />
               <template #verMais>
                  <ComoContarEmEntrevistas />
               </template>
            </ConteudoStepperPapoRetoPainel>
         </PapoRetoPainel>
         <PapoRetoPainel
            :etapa="3"
            titulo="Reonboarding: Como se adaptar ao trabalho após a licença-maternidade"
            class="mt-2"
         >
            <ConteudoStepperPapoRetoPainel
               texto-principal="Nós sabemos: voltar para o trabalho após a licença-maternidade é um dos momentos mais difíceis para uma mãe. Isso ocorre porque o desejo de permane-cer ao lado do bebê, acompanhando cada segundo do seu..."
               fim-url-video-youtube="5e9_LZR6GSI"
            >
               <CardDetalheStepperPapoReto
                  titulo="Já é hora de retomar as atividades ou vale a pena prolongar a licença?"
                  tipo="QUIZ"
                  icone="bi-question-lg"
                  url-direcionamento="/maeelenials/quiz/formulario"
               />
               <template #verMais>
                  <Reonboarding />
               </template>
            </ConteudoStepperPapoRetoPainel>
         </PapoRetoPainel>
         <PapoRetoPainel :etapa="4" titulo="Super mãe: habilidades desenvolvidas com a maternidade" class="mt-2">
            <ConteudoStepperPapoRetoPainel
               texto-principal="É um fato: enquanto empresas retrógradas ainda veem a maternidade como um tabu, empresas visionárias e que buscam transformar seus negócios cada vez mais sustentáveis se beneficiam..."
               fim-url-video-youtube="uwAu_X97frQ"
            >
               <CardDetalheStepperPapoReto
                  titulo="Como evitar um burnout pelo acúmulo de tarefas"
                  texto="Quando estamos imersas na rotina..."
                  tipo="ARTIGO"
                  url-direcionamento="/maeelenials/artigo/evitarburnout"
               />
               <template #verMais>
                  <SuperMae />
               </template>
            </ConteudoStepperPapoRetoPainel>
         </PapoRetoPainel>
         <PapoRetoPainel :etapa="5" titulo="Vem com a mãe: Mães que criam um plano de carreira de sucesso" class="mt-2">
            <ConteudoStepperPapoRetoPainel
               texto-principal="Com a chegada dos filhos, nossas prioridades mudam: muitas mães querem encontrar um trabalho que permita mais tempo de qualidade com as crianças ou cargos mais altos que agreguem..."
               fim-url-video-youtube="y6tlR5rVPok"
            >
               <CardDetalheStepperPapoReto
                  titulo="Template para o seu planejamento de desenvolvimento individual"
                  tipo="TEMPLATE"
                  :acao-direcionamento="abrirModalPlanoDesenvolvimentoIndividual"
               />
               <template #verMais>
                  <VemComAMae />
               </template>
            </ConteudoStepperPapoRetoPainel>
         </PapoRetoPainel>
      </v-expansion-panels>
      <DialogPlanoDesenvolvimentoIndividual />
   </div>
</template>

<script>
import eventBus from '@/event-bus';
import PapoRetoPainel from '@/components/MaeElenials/PapoRetoPainel';
import SuperMae from '@/components/MaeElenials/DetalhamentoPapoReto/SuperMae';
import VemComAMae from '@/components/MaeElenials/DetalhamentoPapoReto/VemComAMae';
import Reonboarding from '@/components/MaeElenials/DetalhamentoPapoReto/Reonboarding';
import ComoContarEmEntrevistas from '@/components/MaeElenials/DetalhamentoPapoReto/ComoContarEmEntrevistas';
import DialogPlanoDesenvolvimentoIndividual from '@/components/MaeElenials/DialogPlanoDesenvolvimentoIndividual';
import ConteudoStepperPapoRetoPainel from '@/components/MaeElenials/ConteudoStepperPapoRetoPainel';
import CardDetalheStepperPapoReto from '@/components/MaeElenials/CardDetalheStepperPapoReto';
import VouSerMaeEAgora from '@/components/MaeElenials/DetalhamentoPapoReto/VouSerMaeEAgora';

export default {
   name: 'PapoRetoMaternidade',
   components: {
      PapoRetoPainel,
      ConteudoStepperPapoRetoPainel,
      CardDetalheStepperPapoReto,
      DialogPlanoDesenvolvimentoIndividual,
      VouSerMaeEAgora,
      ComoContarEmEntrevistas,
      Reonboarding,
      SuperMae,
      VemComAMae
   },
   methods: {
      abrirModalPlanoDesenvolvimentoIndividual() {
         eventBus.$emit('abrir-plano-desenvolvimento-individual-mae-elenials');
      }
   }
};
</script>
