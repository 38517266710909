<template>
   <div class="text-left">
      <div class="text-caption">
         Se tem um tema que, por mais que a gente se prepare, sempre vem com uma carga de emoções nem sempre atreladas
         somente à alegria, é descobrir aquele positivo no teste de gravidez.
      </div>
      <div class="text-body-2 font-weight-bold titulo-card-detalhe-papo-reto mt-4 text-left">VOU SER MÃE. E Agora?</div>
      <div class="text-caption">
         Isso ocorre porque a gestação de um novo ser dentro da gente gera dúvidas, anseios e, às vezes, até mesmo
         inseguranças.
      </div>
      <div class="text-caption mt-4">
         A partir desse momento é dada a largada para todas as transformações: tanto físicas quanto emocionais.
      </div>
      <div class="text-caption mt-4">
         Mas mesmo com aquele impertinente enjôo, com a vontade de fazer xixi o tempo todo, as roupas favoritas que já
         não entram mais e o sono constante, saber que seu corpo está preparando um novo ser dentro de você é algo
         mágico.
      </div>
      <div class="text-caption mt-4">
         E para que esse momento tão especial não sofra interferências, recomendamos algumas medidas no âmbito
         profissional que vão ajudar tanto você a poder organizar melhor seu tempo quanto seus gestores e colegas de
         trabalho a organizarem os processos para o período da sua licença-maternidade:
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 texto-header-etapa-mais-detalhes font-weight-bold">
            1- Seja transparente:
         </strong>
         está insegura sobre quando anunciar a novidade, se logo no início ou no fim do primeiro trimestre? O importante
         é que você anuncie quando se sinta mais confortável, mas procure não ocultar a situação por muito tempo para
         não quebrar o elo de confiança com seus gestores, já que precisam buscar alguém para o tempo em que você
         estiver ausente.
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 texto-header-etapa-mais-detalhes font-weight-bold"> 2- Antecipe-se: </strong>
         afinal, apesar de 9 meses parecer muito tempo, passa voando - ainda mais com a rotina de exames do período
         pré-natal. Crie uma lista com todas as suas atividades atuais, compartilhe os arquivos mais importantes para o
         andamento de projetos, converse com a equipe e tire todas as dúvidas antes de se ausentar.
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 texto-header-etapa-mais-detalhes font-weight-bold">
            3-Mantenha-se ativa:
         </strong>
         para muitas mulheres, ausentar-se do trabalho na reta final da gestação implica um rompimento muito brusco na
         rotina, por isso uma medida que pode amenizar esse sentimento é continuar participando remotamente das reuniões
         ou projetos mais importantes - desde que isso não sobrecarregue ou estresse você, claro.
      </div>
      <div class="text-caption mt-4">
         <strong class="text-subtitle-2 texto-header-etapa-mais-detalhes font-weight-bold">
            4- Relaxe e desfrute:
         </strong>
         relaxar enquanto aguarda a chegada do bebê pode ser um exercício muito empoderador de reflexão e
         autoconhecimento. Mantenha-se tranquila, busque ajude de profissionais da saúde mental, pratique meditação e
         aproveite cada segundo antes de dizer pela primeira vez o que você esperou meses para dizer:
      </div>
      <div class="text-caption mt-4">Boas-vindas ao mundo, bebê!</div>
      <div class="text-caption mt-4">
         Para mais dicas de maternidade e carreira, acesse
         <a @click="abrirUrlMaeElenials"><u>www.maellennials.com</u></a> e faça parte da nossa comunidade.
      </div>
   </div>
</template>

<script>
export default {
   name: 'VouSerMaeEAgora',
   methods: {
      abrirUrlMaeElenials() {
         window.open('https://www.maellennials.com');
      }
   }
};
</script>
