<template>
   <v-app>
      <Header />
      <v-main class="conteudo-principal-mae-elenials">
         <slot />
      </v-main>
   </v-app>
</template>

<script>
import Vue from 'vue';
import Header from '@/components/MaeElenials/Header';

export default {
   name: 'MaeElenials',
   components: { Header },
   mounted() {
      Vue.prototype.$SnackBar = this.$refs.snackBar;
   },
   methods: {
      voltar() {
         this.$router.go(-1);
      }
   }
};
</script>
